import React from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import FlexCard from "../components/flexcard";
import GiftCardBackground from "../components/giftcardbackground";
import settings from "../../settings";

// images
const topbanner = `${settings.IMAGES_BASE_URL}/v2/images/specialoffers/sale-banner.jpg`;
const julyIndyImg = `${settings.IMAGES_BASE_URL}/v2/images/specialoffers/july-indy-offer.jpg`;

const Specialoffersorganic = () => {
  const getFirstCard = () => {
    const today = new Date();
    const julyPromotions =
      today >= new Date(2023,6,1) &&
      today < new Date(2023,8,10);
    if (julyPromotions) {
      return (
        <FlexCard
          rowType="row"
          image={julyIndyImg}
          imageAlt="INDY MOMENTS SHOULD BE SHARED"
          header="INDY MOMENTS SHOULD BE SHARED"
          subheader="BUY ONE INDY CLASS, GET ONE FREE"
          text="Our Half- and Full-Day Experiences at the Indianapolis Motor Speedway are best shared with friends, and now’s your chance to bring them. Buy one Indy class, get one FREE."
          btnText="Call 888-345-4269 to order"
          btnStyle="btn-blue-offers"
          phone="tel:888-345-4269"
        >
          <p>
            Call <a className="phone-number" href="tel:888-345-4269">888-345-4269</a> to book your class today. Use promo code <span className="bold">23BOGOBB</span>. Valid at Indianapolis Experience only.Promotion is valid for any class at Indy between August 17th through September 9th, 2023.
          </p>
        </FlexCard>
      );
    } else {
      return <></>;
    }
  };
  return (
    <Layout>
      <SEO
        title="BMW Performance Driving School | Gift Cards"
        description="Frequently asked questions for new BMW owners who qualify for the BMW Performance Center Delivery Program and are ready to fully experience BMW culture!"
      />
      <div className="giftcardpage">
        <GiftCardBackground
          imageDesktop={topbanner}
          imageMobile={topbanner}
          imageAlt="BMW Gift Card Top Banner"
        />

        <section className="performance-center">
          <div className="container">
            <div className="performance-center__header">
              <h2>Special Offers</h2>
            </div>
          </div>
          {getFirstCard()}
        </section>
      </div>
    </Layout>
  );
};

export default Specialoffersorganic;
